<template></template>

<script>
export default {
	name: "alipay",
	created() {
		const { url } = this.$route.query;
		location.href = url;
	},
}
</script>